import { apiClient } from './axios-intercept';
import { DEFAULT_SERVER } from './conf';
import { StudentType } from './define';

const STUDENT = "/api/v1/student/class";
const WALKIN = "/api/v1/student/walkin/class";

const TEMPLATE = "/api/v1/student/template";
const STUDENT_URL =DEFAULT_SERVER +STUDENT;
const WALKIN_URL =DEFAULT_SERVER +WALKIN;

export const TEMPLATE_URL = DEFAULT_SERVER+TEMPLATE;

export class StudentAPI {
        
    public static list = async (classId: number): Promise<any> => 
    {
        return new Promise(function (resolve, reject) {
            var s=STUDENT_URL+"/"+classId;
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                return resolve(response);
            }).catch(err => {
                console.log("list student error:", err);
                return reject(err); 
            })
        })
    }   

    public static import = async (classId: number,studentType: StudentType,file: FileList): Promise<any> => 
    {
        var formData = new FormData();
        formData.append("file", file[0]);
        formData.append("student_type", studentType);

        return new Promise(function (resolve, reject) {
            var s=STUDENT_URL+"/"+classId;
            apiClient.post(s,formData,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("import student response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("import student error:", err);
                return reject(err); 
            })
        })
    }   

    public static save = async (classId: number,item: any): Promise<any> => 
    {
        return new Promise(function (resolve, reject) {
            var s=STUDENT_URL+"/"+classId;
            apiClient.patch(s,item,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                return resolve(response);
            }).catch(err => {
                console.log("save student error:", err);
                return reject(err); 
            })
        })
    }   

    public static listwalkin = async (classId: number): Promise<any> => 
    {
        return new Promise(function (resolve, reject) {
            var s=WALKIN_URL+"/"+classId;
            apiClient.get(s,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                return resolve(response);
            }).catch(err => {
                console.log("list walkin error:", err);
                return reject(err); 
            })
        })
    }   

    public static savewalkin = async (classId: number,item: any): Promise<any> => 
    {
        return new Promise(function (resolve, reject) {
            var s=WALKIN_URL+"/"+classId;
            apiClient.patch(s,item,
            {
                headers: {
                    "Accept": "application/json"
                }
            })
            .then((response) => {
                console.log("save walkin response : ", response);
                return resolve(response);
            }).catch(err => {
                console.log("save walkin error:", err);
                return reject(err); 
            })
        })
    } 
    


}