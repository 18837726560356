import React, { useEffect, useState } from 'react'
import { createPage } from '../tab/pagetab';


type Props = {
    children?: React.ReactNode;
    current: number;
    total: number;
    pageSize: number;
    postElement?: React.ReactElement;
    loading: boolean;
    customHeader?: React.ReactNode;
    onPageChange?: (value: number) => void;
    onPageSizeChange?: (value: number) => void;
}
export const CustomPage = (props: Props) => {
    const [current, setCurrent] = useState(props.current);
    const [total, setTotal] = useState<number>(props.total);
    const [pageSize, setPageSize] = useState(props.pageSize);
    useEffect(() => {
        setCurrent(props.current);
        setPageSize(props.pageSize);
        setTotal(props.total);
    }, [props.current, props.total, props.pageSize])
    const onPageSizeChange = (value: number) => {
        props.onPageSizeChange?.(value);
    }

    const getTotalPage = () => {
        if (total === 0) return 0;
        return Math.ceil(total / pageSize);
    }
    const handlePage = (page: number) => {
        props.onPageChange?.(page);
    }
    return (
        <>
            {!props.loading && (
                <>
                    <div className="row">
                        <div className="col-5" style={{ justifyContent: "left", textAlign: "left",marginBlock:"auto" }}>
                            {props.customHeader || `จำนวนทั้งหมด ${total} รายการ`}
                        </div>
                        <div className="col-1">
                        </div>
                        <div className="col-6 row" style={{ justifyContent: "right", textAlign: "right", paddingRight: "0px" }}>
                            <div style={{ textAlign: "right", marginTop: "auto", marginBottom: "auto" }}>จำนวนรายการต่อหน้า</div>&nbsp;
                            <select className="form-control" style={{ width: "80px" }} name="page" value={props.pageSize} onChange={e => onPageSizeChange(parseInt(e.target.value))}>
                                <option value={total ? total : 10}>All</option>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            {props.postElement}
                        </div>
                    </div>
                    {props.children}
                    <div className="row" style={{ justifyContent: "center", position: "relative" }}>
                        {createPage(current, getTotalPage(), handlePage)}
                        <div className="col-auto" style={{ justifyContent: "left", textAlign: "left", position: "absolute", left: "0px" }}>
                            จำนวนทั้งหมด {total} รายการ <br />
                        </div>
                    </div>
                </>
            )
            }

        </>

    )
}